//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//


export default {
    props: {
        source: {
            type: Array
        },
        options: {
            type: Object
        },
        templates: {
            type: Array,
            default: () => [

            ]
        }
    },

    data: () => ({
        grid: void 0,
        internal_source: [],
    }),

    computed: {
        defaultTemplates() {
            return [
                {
                    id: 'commands_template',
                    component: 'template-buttons',
                    properties: {
                        buttons: ['delete'],
                        small: true
                    }
                },
                ...this.templates
            ]
        },

        defaultOptions() {
            return {
                showBorders: true,
                showColumnLines: true,
                showRowLines: true,
                rowAlternationEnabled: true,
                allowColumnReordering: true,
                allowColumnResizing: true,
                columnResizingMode: 'widget', //widget or 'nextColumn'
                columnAutoWidth: true,
                columnHidingEnabled: true,
                keyboardNavigation: {
                    enabled: false
                },
                filterRow: {
                    visible: true,
                    applyFilter: 'auto'
                },
                headerFilter: {
                    visible: true
                },
                selection: {
                    mode: 'single'
                },
                editing: {
                    mode: 'form',
                    allowUpdating: true,
                    allowDeleting: true,
                    startEditAction: 'dblClick'
                },
                paging: {
                    pageSize: 10
                },
                pager: {
                    showPageSizeSelector: false,
                    allowedPageSizes: [3, 5, 10, 20],
                    showInfo: true
                },
                groupPanel: {
                    visible: true,
                    emptyPanelText: 'Панель группировки'
                },
                /* columns: [
                    {
                        dataField: 'name',
                        caption: 'Наименование'
                    },
                    {
                        dataField: 'alias',
                        caption: 'Алиас (id)'
                    },
                    {
                        dataField: 'type_id',
                        caption: 'Тип',
                        lookup: {
                            dataSource: this.resource_types,
                            valueExpr: 'id',
                            displayExpr: 'alias'
                        }
                    },
                    {
                        dataField: 'default_set_id',
                        caption: 'Набор по умолчанию',
                        lookup: {
                            dataSource: this.sets,
                            valueExpr: 'id',
                            displayExpr: 'name'
                        }
                    },
                    {
                        type: "buttons",
                    }
                ], */
                onEditorPreparing(e) {
                    e.editorOptions.showClearButton = true;
                },
                customizeColumns(columns) {
                    /* columns.forEach(column => {
                        if(column.type === 'buttons') {
                            //column.cellTemplate = 'permission';
                            column.cellTemplate = 'commands_template';
                        }
                    }); */
                },
                onInitialized: (e) => {
                    this.grid = e.component;
                },
                /* onCellPrepared(e) {
                    if(e.column.command) {
                        e.column.cellTemplate = 'raw_template';
                    }
                } */
                /* onSelectionChanged: self.onSelectionChanged,
                onInitialized: self.onInitialized */
                ...this.options
            }
        }
    },

    watch: {
        source(value) {
            if(value && this.grid) {
                this.grid.beginUpdate();

                this.internal_source = value;

                this.grid.endUpdate();
            }
        }
    }
}
