//
//
//
//
//
//
//
//
//
//
//
//

export default {
    props: {
        visible: {
            type: Boolean,
            default: false,
        },
    },

    data: () => ({
        key: 0,
        dialog: false
    }),

    computed: {
    },

    methods: {
        
    },

    watch: {
        visible(value) {
            /* value && (this.key = this.key + 1); */

            this.dialog = value;
        },

        dialog(value) {
            !value && this.$emit('close');
        },
    }
}
