//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//

import XLSX from 'xlsx';

export default {
    async fetch() {
        const [{ content: databases }, { content: profiles }] = await Promise.all([
            this.$api.fetch.databases(),
            this.$api.fetch.profiles()
        ]);

        this.databases = databases;
        this.profiles = profiles;
        this.profile = 2;
    },

    data: () => ({
        internal_db: void 0,
        database: void 0,
        databases: [],

        profile: void 0,
        profiles: [],
        operators: [],
        selected_operators: [],

        files: [],

        errors: void 0,

        menu: false,
        date: (new Date(Date.now() - (new Date()).getTimezoneOffset() * 60000)).toISOString().substr(0, 10),
        dates: [],

        uploading: false,
        upfiles: {},
        loaded: false,
        file_no: 0,
        files_count: 0
    }),

    computed: {
        database_items() {
            const reduced = this.databases.reduce((memo, item, inx) => {
                memo[item.meta.name] = {
                    text: item.meta.name,
                    value: `${item.database}:${inx}`
                };

                return memo;
            }, {});

            return Object.values(reduced);
        }
    },

    methods: {
        async report() {
            this.menu = false;

            const { database, dates } = this;

            const { content } = await this.$api.fetch.report({ database, dates, operators: this.selected_operators });

            const wb = XLSX.utils.book_new();

            const headers = [
                'Название файла',
                'Кадастровый номер',
                'Название проекта',
                'Дата актуальности',
                'Последняя дата регистрации',
                'Кол-во строк',
                'Пользователь'
            ];

            const ws_data = content.map(data => {
                const filename = decodeURIComponent(data.name);

                return [
                    filename,
                    data.meta.info.cadastral_number,
                    data.meta.info.projects,
                    data.meta.info.document_date,
                    data.meta.info.last_date,
                    +data.meta.data,
                    [data.firstname, data.lastname].filter(item => !!item).join(' ')
                ];
            });

            const ws = XLSX.utils.aoa_to_sheet([
                headers,
                ...ws_data
            ]);

            XLSX.utils.book_append_sheet(wb, ws, 'Статистика');
            XLSX.writeFile(wb, `statement-${dates.sort().join('-')}.xlsx`);
        },

        required(value) {
            return !!value || 'Поле обязательно для заполнения.';
        },

        filled(value) {
            return value.length ? true : 'Поле обязательно для заполнения.';
        },

        async upload() {
            this.errors = void 0;

            if(!this.$refs.form.validate()) {
                return;
            }

            this.uploading = true;
            this.upfiles = {};
            this.loaded = false;

            let { database, profile } = this;

            this.files_count = this.files.length;
            this.file_no = 0;

            for(const file of this.files) {
                this.file_no = this.file_no + 1;

                const params = {
                    encoding: 'win1251',
                    name: encodeURIComponent(file.name),
                    size: file.size
                };

                this.$set(this.upfiles, file.name, { ...params, status: 'загрузка...', error: '' });

                const data = new FormData();
                data.append('file', file, JSON.stringify(params));

                data.append('count', 1);
                database && data.append('database', database);
                profile && data.append('profile', profile);

                //await new Promise(resolve => setTimeout(resolve, 2000));

                //this.upfiles[file.name].status = '100% OK';
                //this.upfiles[file.name].error = 'FAIL';

                if(this.files.length) {
                    const { content, error } = await this.$api.upload.files(data, { silent: true }).catch(error => {
                        return { error };
                    });

                    let errors = error ? [error] : content.errors.length ? content.errors : [];

                    if(errors.length) {
                        let [error] = errors;

                        this.upfiles[file.name].error = error.message;
                    }
                    else {
                        this.upfiles[file.name].status = '100% OK';
                    }
                }
            }

            this.$store.dispatch('setDatabase', database);

            this.profile = void 0;
            this.files = [];

            this.$refs.form && this.$refs.form.resetValidation();

            this.$nextTick(() => this.database = database);

            this.loaded = true;
        },

        async signout() {
          await this.$api.auth.signout();
          this.$store.commit('SET_ACCOUNT', {});
        }
    },

    watch: {
        internal_db(value) {
            const [database] = value.split(':');

            this.database = database;
        },

        async database(database) {
            //const [database] = value.split(':');

            const { content: operators } = await this.$api.fetch.operators({ database });

            if(operators?.length) {
                this.operators = operators.map(operator => {
                    const name = [operator.firstname, operator.lastname].filter(x => !!x).join(' ');

                    operator = {
                        id: operator.id,
                        name
                    };

                    return operator;
                });
            }
            else {
                this.operators = [];
            }

            this.$store.dispatch('setDatabase', database);
        }
    }
}
