import { render, staticRenderFns } from "./files.vue?vue&type=template&id=aca7effc&lang=html&"
import script from "./files.vue?vue&type=script&lang=js&"
export * from "./files.vue?vue&type=script&lang=js&"
import style0 from "./files.vue?vue&type=style&index=0&lang=css&"


/* normalize component */
import normalizer from "!../node_modules/vue-loader/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  null,
  null
  
)

export default component.exports

/* nuxt-component-imports */
installComponents(component, {DataGrid: require('/app/components/data-grid.vue').default})


/* vuetify-loader */
import installComponents from "!../node_modules/vuetify-loader/lib/runtime/installComponents.js"
import { VCard } from 'vuetify/lib/components/VCard';
import { VCardText } from 'vuetify/lib/components/VCard';
import { VCardTitle } from 'vuetify/lib/components/VCard';
installComponents(component, {VCard,VCardText,VCardTitle})
