//
//
//
//
//
//
//
//
//
//
//

import { mapState, mapGetters } from 'vuex';

export default {
    data: () => ({
        database: ''
    }),

    computed: {
        ...mapState({
            allowed: state => state.account && !state.account.expired && state.account.type === 'user',
            account: state => state.account
        })
    },

    methods: {
        onDatabaseChanged(value) {
            this.database = value;
        }
    }
};
