//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//

export default {
    async fetch() {
        const [{ content: databases }] = await Promise.all([
            this.$api.fetch.databases(),
        ]);

        this.databases = databases;
    },

    data: () => ({
        email: void 0,
        password: void 0,
        database: void 0,
        databases: [],
        messageColor: 'gray',
        messageText: 'Введите все поля',
    }),

    computed: {
      database_items() {
        const reduced = this.databases.reduce((memo, item) => {
          memo[item.meta.name] = {
            text: item.database,
            value: item.database,
          };

          return memo;
        }, {});

        return Object.values(reduced);
      }
    },

    methods: {
        async onSubmit(event) {
            event.preventDefault();

            const { email, password, database } = this;

            if (!email || !password || !database) {
                this.messageColor = 'red';
                this.messageText = 'Все поля обязательны!';
                return;
            }

            const response = await this.$api.auth.signin({ email, password, database });

            if (response?.error?.message) {
              this.messageColor = 'red';
              this.messageText = response.error.message;
            }
        },

        required(value) {
          return !!value || 'Поле обязательно для заполнения.';
        },
    }
}
