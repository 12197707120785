//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//

import { mapState, mapGetters } from 'vuex';

export default {
    props: {
        /* database: {
            type: String
        } */
    },

    async fetch() {
        /* const [{ content: databases }, { content: profiles }] = await Promise.all([
            this.$api.fetch.databases(),
            this.$api.fetch.profiles()
        ]);

        this.databases = databases;
        this.profiles = profiles; */
    },

    data: () => ({
        //files: []
    }),

    computed: {
        ...mapState({
            database: state => state.database
        }),
        files() {
            return this.$store.state.entities[this.database] ? Object.values(this.$store.state.entities[this.database]) : [];
        },
        templates() {
            return [
                {
                    id: 'stage',
                    component: 'template-stage',
                    properties: {
                        buttons: ['create', 'edit', 'delete'],
                        'x-small': true
                    }
                },
                {
                    id: 'file-name',
                    component: 'template-file-name'
                },
                {
                    id: 'control',
                    component: 'template-control'
                },
                {
                    id: 'dates',
                    component: 'template-dates'
                },
                {
                    id: 'dates-header',
                    component: 'template-dates-header'
                }
            ]
        },

        options() {
            return {
                columnResizingMode: 'nextColumn',
                columnHidingEnabled: false,
                paging: {
                    pageSize: 20
                },
                pager: {
                    showPageSizeSelector: true,
                    allowedPageSizes: [20, 50, 500],
                    showInfo: true
                },
                columns: [
                    {
                        dataField: 'created_at',
                        caption: 'Дата загрузки',
                        sortIndex: 0,
                        sortOrder: 'desc',
                        customizeText: (e) => {
                            return this.$api.$moment.tz(e.value, 'UTC').format('DD.MM.YYYY HH:mm:ss');
                        }
                    },
                    {
                        dataField: 'user_name',
                        caption: 'Пользователь'
                    },
                    {
                        dataField: 'name',
                        caption: 'Файл',
                        cellTemplate: 'file-name',
                        cssClass: 'max-width'
                    },
                    {
                        dataField: 'meta.stage',
                        caption: 'Статус',
                        cellTemplate: 'stage',
                        minWidth: 150
                    },
                    {
                        dataField: 'meta.stage',
                        caption: 'Управление',
                        cellTemplate: 'control',
                        cssClass: 'max-width'
                    },
                    {
                        dataField: 'meta.info.document_date',
                        caption: 'Дата актуальности',
                        headerCellTemplate: 'dates-header',
                        cellTemplate: 'dates'
                    },
                    {
                        dataField: 'meta.info.cadastral_number',
                        caption: 'Кадастровый номер'
                    },
                    {
                        dataField: 'meta.info.projects',
                        caption: 'Проекты',
                        cssClass: 'max-width'
                    },
                    {
                        type: "buttons",
                        visible: false
                    }
                ],
            }
        }
    },

    methods: {
        
    },

    watch: {
        /* async database(value) {
            const { content = [] } = value ? await this.$api.fetch.files({ database: value }, { silent: true }) : {};

            this.$store.commit('SET_ENTITIES', { group: value, data: content });
            //this.files = content;
        } */
    }
}
